<template>
  <div class="favPage">
    <section class="playlistTopPart" v-if=" this.$store.state.favorite.playlistName">
      <div class="container">
        <h1>{{ this.$store.state.favorite.playlistName }}</h1>
        <hr>
      </div>

    </section>
    <section class="fav-items-container" v-if="this.$store.state.favorite.playListItems.length>0">
      <div class="container">
        <div class="row justify-content-center">
          <div
              class="col-lg-3 col-md-4 col-6 " v-bind:key="item.id"
              v-for="item in this.$store.state.favorite.playListItems"
          >
            <div class="episode-item">
              <router-link
                  :to="{name: 'Episode', params:{id:item.id}}"
              >
                <img :src="item.image_full_path" alt="Episode Image"/>
                <h5>
                  {{ item.title }}
                </h5>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "favorite",

  watch: {
    $route(to, from) {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      this.$store.commit("favorite/updatePlaylist", []);
      this.$store.commit("favorite/updatePlaylistName", "");
      this.$store.dispatch("loading/show");
      if (this.$route.params.isFav == 1) {
        this.$store.dispatch("favorite/getPlaylist", {
          "type": 'latest'
        })
            .then(res => {
              this.$store.dispatch("loading/hide");
            })
            .catch(err => {
            });
      } else {
        this.$store.dispatch("favorite/getPlaylist", {
          "id": this.$route.params.id,
          "type": this.$route.params.isRegion == 1 ? 'region' : 'category'
        })
            .then(res => {
              this.$store.dispatch("loading/hide");
            })
            .catch(err => {
            });
      }
    },
  },
  created() {
    this.getItems();
  },
}
</script>

<style lang="scss" scoped>
.favPage {
  .fav-top-section {
    //margin-top: 3em;
    padding: 3em 0;
    background-color: #f5f6f7;

    .imageContainer {
      padding: 2em;
      border-radius: 8px;
      box-shadow: 0 0 12px rgba(#000000, 0.1);

      img {
        width: 100%;
        object-fit: cover;

      }
    }

    .text-part {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      h1.page-title {
        color: $myViolet;
        font-weight: 700;
      }
    }
  }

  .playlistTopPart {
    padding-top: 3em;

    h1 {
      font-weight: 700;
      color: $myViolet;
    }
  }

  .fav-items-container {
    padding: 1.5em 0 3em 0;

    .episode-item {
      padding: 1.5em 0;
      border-radius: 8px;
      //box-shadow: 0 0 12px rgba(#000000, 0.1);
      overflow: hidden;
      //background-color: #ffffff;
      transition: 0.3s $bounceAnimation;

      &:hover {
        transform: scale(1.05);
      }

      a {
        display: block;
      }

      img {
        width: 100%;
        //height: 20em;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        aspect-ratio: 1/1;
      }

      h5 {
        margin-top: 1em;
        color: $myViolet;
        font-size: 1.35rem;
        font-weight: 700;
      }

      .episode-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1em;

        .info-item {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            margin-inline-end: 0.5em;
            color: $myYellow;
          }

          p {
            margin-bottom: 0;
            font-weight: 700;
            color: $myBlue;
          }
        }
      }
    }
  }
}
</style>